import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import images from "./Assets/sc.png"

const Doc = () => {
  return (
    <Container>
        <NavLink to="gettingstarted">
            <Text>Getting Started</Text>
        </NavLink>
    </Container>
  )
}

export default Doc
const Text = styled.h1`
    color: white;
    font-size: 60px;
    text-decoration: none;
`

const Container = styled.div`
    width: 100%;
    height: 88vh;
    background-image: url(${images});
    display: flex;
    justify-content: center;
    align-items: center;
`