import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'


const GettingStarted = () => {
  return (
    <Container>
        We started
    </Container>
  )
}

export default GettingStarted

const Container = styled.div`
    
`