import React from 'react'
import styled from 'styled-components'
import images from "./Assets/sc.png"

const Home = () => {
  return (
    <Container>
        <Text>HOME</Text>
    </Container>
  )
}

export default Home

const Text = styled.h1`
    color: white;
    font-size: 60px;
`

const Container = styled.div`
    width: 100%;
    height: 88vh;
    background-image: url(${images});
    display: flex;
    justify-content: center;
    align-items: center;
`