import React from 'react'
import styled from 'styled-components'
import images from "./Assets/ss.png"

const Home = () => {
  return (
    <Container>
        <Text>About</Text>
    </Container>
  )
}

export default Home

const Text = styled.h1`
    color: white;
    font-size: 60px;
`

const Container = styled.div`
    width: 100%;
    height: 88vh;
    background-image: url(${images});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
`