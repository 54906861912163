import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import images from "./Assets/freepik.png"

const Error = () => {
  return (
    <Container>
        <Wrapper>
            <Left>
                <Top>Oops!</Top>
                <Text>We can't seem to find the page you are looking for.</Text>
                <Err>Error code: 404</Err>
                <Help>Here are some helpful links instead:</Help>
                <Tynker><a href="https://tynker-assignment.netlify.app/">Tynker.com</a></Tynker>
                <Tynker><a href="resolution-assignment.netlify.app">Resolution.com</a></Tynker>
                <Tynker><a href="https://eloquent-gingersnap-6f9c64.netlify.app/">Utiva.com</a></Tynker>
                <Tynker><a href="https://vercel-assignment.netlify.app/">Vercel.com</a></Tynker>
            </Left>
            <Right>
                <Images src={images} />
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Error
const Tynker = styled.div`
    color: #484648;
    font-size: 17px;
    margin-left: 25px;
    margin-top: 10px;
    text-decoration: none;
    a{
        /* color: #484648; */
        text-decoration: none;
    }
`
const Help = styled.div`
    color: #484648;
    font-size: 21px;
    margin-left: 25px;
    margin-top: 20px;
`

const Err = styled.div`
    color: #484648;
    font-size: 21px;
    margin-left: 25px;
    margin-top: 20px;
`

const Text = styled.div`
    color: #484648;
    font-size: 25px;
    margin-left: 25px;
`

const Top = styled.h1`
    color: #484648;
    font-size: 80px;
    margin-left: 25px;
`

const Images = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Right = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

const Left = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Wrapper = styled.div`
    width: 95%;
    height: 100%;
    display: flex;
`

const Container = styled.div`
    width: 100%;
    height: 88vh;
    display: flex;
    justify-content: center;
`