import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import images from "./Assets/logo2.png"


const Header = () => {
  return (
    <Container>
        <Wrapper>
              <Left>
            <Images src={images} />
        </Left>
        <Mid>
            <NavLink to="/">
            <Nav>Home</Nav>
            
        </NavLink>
        <NavLink to="/about">
            <Nav>About</Nav>
        </NavLink>
        <NavLink to="/contact">
            <Nav>Contact</Nav>
        </NavLink>
        <NavLink to="/docs">
            <Nav>Doc</Nav>
        </NavLink>
        </Mid>
        <Right>
            <Button>Log In</Button>
        </Right>
        </Wrapper>
    </Container>
  )
}

export default Header

const Wrapper = styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Button = styled.div`
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484648;
    border: 1px solid  #484648;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
`
const Right = styled.div`
    display: flex;
`

const Images = styled.img`
    height: 70px;
`

const Left = styled.div`
    display: flex;
`

const Mid = styled.div`
    display: flex;
`

const Nav = styled.h3`
    color:  #484648;;
    margin-right: 90px;
    text-decoration: underline;
`

const Container = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
`