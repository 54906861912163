import React from 'react'
import {  useRoutes  } from "react-router-dom"
import About from './About'
import Contact from './Contact'
import Doc from './Doc'
import GettingStarted from './GettingStarted'
import Home from './Home'
import Error from "./Error"


const Routes = () => {

    let element = useRoutes([
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/about",
            element: <About />
        },
         {
            path: "/contact",
            element: <Contact />
        },
         {
            path: "/docs",
            children: [
                {
                    index: true,
                    element: <Doc />
                },
                {
                    path: "gettingstarted",
                    element: <GettingStarted />
                }
            ]
        },
        {
            path: "*",
            element: <Error />
        },
    ])

  return element
}

export default Routes